<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-form ref="form" v-model="valid">
          <v-text-field :rules="rules" label="OCC"                      v-model="objetivos.occ"></v-text-field>
          <v-text-field :rules="rules" label="ADR"                      v-model="objetivos.adr"></v-text-field>
          <v-text-field :rules="rules" label="REVPAR"                   v-model="objetivos.revpar"></v-text-field>
          <v-text-field :rules="rules" label="ROOMS"                    v-model="objetivos.rooms"></v-text-field>
          <v-text-field :rules="rules" label="GUEST"                    v-model="objetivos.guest"></v-text-field>
          <v-text-field :rules="rules" label="GUEST/ROOMS"              v-model="objetivos.guest_rooms"></v-text-field>
          <v-text-field :rules="rules" label="F&B GUEST"                v-model="objetivos.fnbGuest"></v-text-field>
          <v-text-field :rules="rules" label="VENTAS TOTALES (ROOMS)"   v-model="objetivos.ventasTotalesRooms"></v-text-field>
          <v-text-field :rules="rules" label="VENTAS TOTALES (GUEST)"   v-model="objetivos.ventasTotalesGuest"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn color="primary darken-1" text @click.native="agree">Guardar</v-btn>
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    rules: [v => !isNaN(v) || "Introduce un número"],
    valid: true,
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    objetivos: {
      occ:0,
      adr:0,
      revpar:0,
      rooms:0,
      guest:0,
      guest_rooms:0,
      fnbGuest:0,
      ventasTotalesRooms:0,
      ventasTotalesGuest:0,
    },
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, objetivos) {
      this.dialog = true
      this.title = title
      this.objetivos = Object.assign(this.objetivos, objetivos)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (this.$refs.form.validate()){
        this.resolve(this.objetivos)
        this.dialog = false
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>